<template>
  <v-dialog v-model="dialog" style="zIndex: 1000;" max-width="1500" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">Buscar eventos</v-toolbar-title>
      </v-toolbar>
      <v-text-field autofocus ref="buscadorInput" dense filled hide-details="" v-model="cliente" placeholder="Cliente"></v-text-field>
      <v-card-text class="pa-4" style="max-width: 100%;overflow: auto;">
        <table v-if="ev.length > 0" style="border-collapse: collapse;">
          <thead>
            <tr>
              <th style="white-space: nowrap;">DÍA</th>
              <th style="white-space: nowrap;">FECHA</th>
              <th style="white-space: nowrap;">EVENTO</th>
              <th style="white-space: nowrap;">AD</th>
              <th style="white-space: nowrap;">N</th>
              <th style="white-space: nowrap;">TOT</th>
              <th style="white-space: nowrap;">ESPACIO</th>
              <th style="white-space: nowrap;">SALÓN</th>
              <th style="white-space: nowrap;">HORARIO</th>
              <th style="white-space: nowrap;">BREVE DATO</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(e, i) in ev">
              <tr style="cursor: pointer;" class="hovered" @click="res(e.date)">
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;text-align: center;">{{ new Date(e.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase() }}</td>
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;text-align: center;">{{ ('0' + new Date(e.date).getDate()).slice(-2) }}-{{ new Date(e.date).toLocaleDateString('esp', { month : 'short'}).toUpperCase() }} {{ new Date(e.date).toLocaleDateString('esp', { year : 'numeric'}).toUpperCase() }}</td>
                <td class="py-1 px-1 font-weight-bold" :style="'border: 1px solid var(--v-secondary-base);white-space: nowrap;color: ' + e.color">{{ e.datos.novios || e.datos.nino || e.datos.tipoCelebracion || e.cliente.nombreCompleto.split(" ")[0] || "" }}</td>
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;text-align: center;">{{ (+e.datos.nAdultos || 0) }}</td>
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;text-align: center;">{{ (+e.datos.nNinos || 0) }}</td>
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;text-align: center;">{{ (+e.datos.nAdultos || 0) + (+e.datos.nNinos || 0) }}</td>
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;text-align: center;">{{ e.siglasEspacio }}</td>
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;text-align: center;">{{ e.datos.idEspacio == 10 ? e.datos.ciudadCat : ((e.siglasSalon != 'null' && e.siglasSalon) ? e.siglasSalon : '') }}</td>
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;text-align: center;">{{ e.siglasHorario }}</td>
                <td class="py-1 px-1 font-weight-bold" style="border: 1px solid var(--v-secondary-base);white-space: nowrap;">{{ e.datos.breveDato }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <template v-else>
          SIN DATOS
        </template>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	props: ["eventos"],
  data: () => ({
    dialog: false,
    cliente: '',
  }),
  computed:{
    ev (){
      if (this.cliente.length > 0){
        return this.eventos.filter(e => e.name.toLowerCase().includes(this.cliente.toLowerCase()) && e.idTipoEvento != 6)
      }
      else return 0
    }
  },
  methods: {
    open(){
      this.dialog = true
    },
    cancel(){
      this.dialog = false
      this.cliente = ''
      this.$refs.buscadorInput.blur()
    },
    res(d){
      this.$emit("seleccionar", new Date(d).getTime())
      this.dialog = false;
    }
  },
}
</script>

<style>
.hovered:hover{
  background-color: #575757 !important;
}
</style>